@charset "UTF-8";
/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/
@font-face {
  font-family: "AvenirNextLTW01";
  src: url("./assets/fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
  src: url("./assets/fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix") format("eot"), url("./assets/fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"), url("./assets/fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"), url("./assets/fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "AvenirNextLTW01";
  src: url("./assets/fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix");
  src: url("./assets/fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix") format("eot"), url("./assets/fonts/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2") format("woff2"), url("./assets/fonts/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff"), url("./assets/fonts/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "AvenirNextLTW01";
  src: url("./assets/fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix");
  src: url("./assets/fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix") format("eot"), url("./assets/fonts/14c73713-e4df-4dba-933b-057feeac8dd1.woff2") format("woff2"), url("./assets/fonts/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff") format("woff"), url("./assets/fonts/890bd988-5306-43ff-bd4b-922bc5ebdeb4.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

* {
  box-sizing: border-box; }

html {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden; }

body {
  margin: 0;
  padding: 0; }

::selection {
  background-color: #3BB0B7;
  color: #f5f4f2;
  opacity: 0.7; }

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none; }

li {
  list-style: none; }

img,
svg {
  max-width: 100%;
  vertical-align: middle; }

.tool-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden; }
  @media (min-width: 1024px) {
    .tool-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 4.875rem 1fr 4.0625rem;
      grid-template-areas: "header controls" "graph controls" "graph footer" "secondary-footer secondary-footer";
      align-content: center;
      justify-content: center; } }
  .tool-wrap .investment-context-tip {
    padding: 0.5rem 0;
    font-size: 0.95rem;
    color: #777;
    text-align: center; }

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  .investment-context-disclaimer {
    padding-top: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 20px;
    font-size: 0.6rem;
    color: #777;
    text-align: left;
    line-height: 8pt; } }

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  .investment-context-disclaimer {
    padding-top: 0.1rem;
    padding-left: 5rem;
    padding-right: 3rem;
    padding-bottom: 20px;
    font-size: 0.6rem;
    color: #777;
    text-align: left;
    line-height: 8pt; } }

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  .investment-context-disclaimer {
    padding-top: 0.1rem;
    padding-left: 150px;
    padding-right: 120px;
    padding-bottom: 20px;
    font-size: 0.6rem;
    color: #777;
    text-align: left;
    line-height: 8pt; } }

html {
  font-size: 15px; }
  @media (min-width: 1365px) {
    html {
      font-size: 16px; } }

body {
  font-size: 1rem;
  font-weight: normal;
  font-family: "AvenirNextLTW01", sans-serif;
  line-height: 1.625rem;
  color: #2D2926; }

h1, h2, h3, h4, h5, b, strong {
  font-weight: 800; }

h1 {
  position: relative;
  margin-bottom: 3.25rem;
  font-size: 2.3125rem;
  line-height: 2.4375rem;
  text-transform: uppercase; }

h2 {
  font-size: 1.625rem;
  line-height: 1.625rem;
  margin-bottom: 2.4375rem; }
  @media (min-width: 1365px) {
    h2 {
      font-size: 2.0625rem;
      line-height: 2.4375rem; } }

h3 {
  font-size: 1.625rem; }

h4 {
  font-size: 1.4375rem; }

h5 {
  font-size: 1.125rem; }

h6 {
  font-size: 1rem;
  font-weight: 700; }

p {
  font-size: 1rem; }

em {
  font-style: italic; }

.title-underline {
  position: relative;
  margin-bottom: 2.4375rem; }
  .title-underline::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1.625rem;
    height: 0.40625rem;
    width: 4.875rem;
    background-color: #FFC72C; }

button {
  cursor: pointer;
  outline: 0;
  border: none;
  background-color: transparent;
  font-family: "AvenirNextLTW01", sans-serif; }

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.40625rem 1.625rem;
  border-radius: 0.54167rem;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  letter-spacing: 2px;
  border: 1px solid transparent;
  background-position-x: 0%;
  background-size: 200% 100%;
  transition: background-position-x 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .button:hover {
    background-position-x: 100%; }
  .button.primary-button, .button.ui.primary {
    background: #4EC0C8;
    background-image: linear-gradient(90deg, #47C9D1 0%, #009CA6 50%, #4EC0C8 100%);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); }

.link-button {
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 800;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  color: #2D2926; }

.back-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #2D2926; }
  .back-button svg {
    width: 0.8125rem;
    height: 0.8125rem;
    margin-right: 0.8125rem;
    fill: #2D2926; }

.icon-button {
  padding: 0.54167rem; }
  .icon-button svg {
    height: 1.08333rem;
    fill: #fff; }

.input {
  width: 100%;
  margin-bottom: 1.625rem;
  border-bottom: 2px solid #FFC72C;
  text-align: left;
  color: #FFC72C; }
  .input input {
    width: 100%;
    padding-bottom: 0.40625rem;
    font-family: "AvenirNextLTW01", sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.625rem;
    background-color: transparent;
    border: none; }
    .input input::placeholder {
      color: #d5d2d3;
      font-weight: 400; }

.header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #151211;
  padding: 0.8125rem; }
  .header .header-logo {
    width: 1.625rem;
    height: 1.625rem; }
  @media (min-width: 1024px) {
    .header {
      padding: 0 1.625rem; }
      .header .header-logo {
        width: 2.4375rem;
        height: 2.4375rem; } }
  .header .walkthrough-link {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    color: #BFB8AF;
    text-decoration: none; }
    .header .walkthrough-link svg {
      width: 1.3rem;
      margin-left: 0.54167rem;
      fill: #BFB8AF; }

.secondary-footer {
  grid-area: secondary-footer;
  background-image: linear-gradient(90deg, #2D2926 0%, #151211 50%);
  padding: 0.40625rem;
  text-align: center; }
  .secondary-footer .link-button {
    color: #796E65;
    font-weight: 400; }

.footer {
  grid-area: footer;
  display: flex;
  justify-content: center;
  padding: 0.8125rem;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 100; }

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2); }
  100% {
    box-shadow: 0 0 0 1rem rgba(90, 153, 212, 0); } }

.apply-button {
  flex-basis: 80%;
  max-width: 30rem;
  animation: pulse 3s infinite; }

.apply-button:hover {
  flex-basis: 80%;
  max-width: 30rem;
  animation-play-state: paused;
  color: white; }

.ReactModal__Overlay {
  z-index: 1000; }

.explore-button {
  flex-basis: 100%;
  animation: pulse 3s infinite; }

.tabs {
  grid-area: controls; }

.hidden-tab {
  display: none; }
  @media (min-width: 1024px) {
    .hidden-tab {
      display: block; } }

.tab-buttons-wrap {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 1.625rem; }
  .tab-buttons-wrap button {
    padding: 0.8125rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: inherit;
    font-size: 0.75rem; }
    .tab-buttons-wrap button.tab-active {
      position: relative;
      font-weight: 700; }
      .tab-buttons-wrap button.tab-active::after {
        content: '';
        position: absolute;
        width: 2rem;
        left: 50%;
        transform: translateX(-1rem);
        bottom: 0;
        height: 2px;
        background-color: #FFC72C; }

.tooltip {
  position: absolute;
  min-width: 10rem;
  z-index: 5;
  padding: 0.8125rem;
  border-radius: 2px;
  background-color: #2D2926;
  line-height: 1.3rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2); }
  .tooltip::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 0.40625rem solid transparent;
    border-right: 0.40625rem solid transparent; }
  .tooltip.top {
    bottom: 90%; }
    .tooltip.top::after {
      bottom: -0.40625rem;
      border-top: 0.40625rem solid #2D2926; }
  .tooltip.bottom {
    top: 90%; }
    .tooltip.bottom::after {
      top: -0.40625rem;
      border-bottom: 0.40625rem solid #2D2926; }
  .tooltip.left {
    left: 0.8125rem;
    margin-right: 0.8125rem; }
    .tooltip.left::after {
      left: 0.8125rem; }
  .tooltip.mid {
    left: 50%;
    transform: translateX(-50%); }
    .tooltip.mid::after {
      left: 50%;
      transform: translateX(-50%); }
  .tooltip.right {
    right: 0.8125rem;
    margin-left: 0.8125rem; }
    .tooltip.right::after {
      right: 0.8125rem; }
  .tooltip p {
    font-size: 0.875rem;
    color: #fff; }

.onboard-note-wrap {
  display: flex;
  width: 100%;
  align-items: center; }
  .onboard-note-wrap .onboard-note {
    width: 60%;
    max-width: 10rem; }
    .onboard-note-wrap .onboard-note p {
      font-size: 0.875rem;
      line-height: 1.08333rem;
      color: #2D2926; }
  .onboard-note-wrap.colour-reversed .onboard-note p {
    color: #fff; }
  .onboard-note-wrap.top {
    margin-bottom: 1.625rem; }
  .onboard-note-wrap.bottom {
    margin-top: 1.625rem; }
  .onboard-note-wrap.left {
    justify-content: flex-start; }
    .onboard-note-wrap.left p {
      text-align: left; }
  .onboard-note-wrap.right {
    justify-content: flex-end; }
    .onboard-note-wrap.right p {
      text-align: right; }
  .onboard-note-wrap img {
    height: 2.03125rem;
    padding: 0 0.8125rem; }

.graph-tab-buttons-wrap {
  border-bottom: 1px solid #151211; }
  .graph-tab-buttons-wrap button {
    color: #BFB8AF; }
    .graph-tab-buttons-wrap button.tab-active {
      color: #d5d2d3; }
    .graph-tab-buttons-wrap button.disabled {
      cursor: not-allowed;
      color: #796E65; }
      .graph-tab-buttons-wrap button.disabled::after {
        display: none; }

.graph-wrap {
  grid-area: graph;
  background-color: #151211;
  background-image: linear-gradient(90deg, #2D2926 0%, #151211 50%); }

.graph {
  min-width: 18.75rem;
  max-width: 30rem;
  height: 14rem;
  margin: 1.625rem auto;
  padding-right: 0.8125rem;
  font-size: 0.75rem; }
  @media (min-width: 1024px) {
    .graph {
      max-width: 80%;
      height: 50%;
      margin-top: 12%; } }

.recharts-cartesian-grid line {
  stroke: #2D2926; }
  .recharts-cartesian-grid line.recharts-cartesian-axis-tick-line, .recharts-cartesian-grid line.recharts-cartesian-axis-line {
    display: none; }

.recharts-cartesian-grid rect {
  fill: transparent; }

.line path {
  stroke-width: 1.2px;
  stroke-linecap: round; }

.transparent path {
  stroke: rgba(0, 0, 0, 0); }

.line-lima_aggressive path {
  stroke-width: 3px;
  stroke: #4DAEFB; }

.line-lima_moderate path {
  stroke-width: 3px;
  stroke: #56F1FD; }

.line-Voyager path {
  stroke-width: 3px;
  stroke: lightblue; }

.line-vanguard_aggressive path {
  stroke: #73E831; }

.line-vanguard_moderate path {
  stroke: #B1EF8F; }

.line-vanguard_conservative path {
  stroke: #4CB07D; }

.line-eaa_aggressive path {
  stroke: orange; }

.line-eaa_moderate path {
  stroke: yellow; }

.line-eaa_conservative path {
  stroke: #f6cf67; }

.line-vanguard_fund path {
  stroke: #9174E6;
  stroke-dasharray: 2,2; }

.line-msci_fund path {
  stroke: #7086B7;
  stroke-dasharray: 2,2; }

.line-sp500_fund path {
  stroke: #DE5574;
  stroke-dasharray: 2,2; }

.line-ftse_fund path {
  stroke: #ff3333;
  stroke-dasharray: 2,2; }

.line-high path {
  stroke: #71CCB8; }

.line-low path {
  stroke: #FC5A61; }

.line-medium path {
  stroke-width: 4px;
  stroke: #f5f4f2; }

.recharts-dot {
  stroke: #fff;
  fill: #fff; }

.recharts-tooltip-cursor {
  display: none; }

.recharts-label tspan {
  text-transform: uppercase;
  fill: #009CA6;
  font-size: 0.75rem;
  letter-spacing: 1px;
  opacity: 0.8; }

.graph-tooltip {
  background-color: #fff;
  padding: 0.40625rem 0.8125rem;
  border-radius: 2px;
  line-height: 1.3rem; }
  .graph-tooltip h6 {
    font-size: 0.75rem;
    color: #BFB8AF; }
  .graph-tooltip p {
    font-size: 0.75rem;
    color: #2D2926; }
  .graph-tooltip span {
    color: #796E65;
    text-transform: capitalize; }

@media (min-width: 1024px) {
  .results-wrap {
    margin: 1.625rem auto;
    width: 80%; } }

.results-wrap .results {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 0.8125rem; }
  .results-wrap .results .keys {
    flex: 1 0 26%;
    margin: 1%; }
  .results-wrap .results .result {
    font-weight: 400;
    font-size: 0.75rem;
    position: relative;
    padding-left: 0.8125rem;
    color: #fff;
    border-left: 2px solid #fff;
    line-height: 0.875rem; }
    .results-wrap .results .result.result-high {
      border-color: #71CCB8; }
    .results-wrap .results .result.result-low {
      border-color: #FC5A61; }
    .results-wrap .results .result.result-lima_aggressive {
      border-color: #4DAEFB;
      border-width: 4px; }
    .results-wrap .results .result.result-lima_moderate {
      border-color: #56F1FD;
      border-width: 4px; }
    .results-wrap .results .result.result-Voyager {
      border-color: lightblue;
      border-width: 4px; }
    .results-wrap .results .result.result-vanguard_aggressive {
      border-color: #73E831; }
    .results-wrap .results .result.result-vanguard_moderate {
      border-color: #B1EF8F; }
    .results-wrap .results .result.result-vanguard_conservative {
      border-color: #4CB07D; }
    .results-wrap .results .result.result-eaa_aggressive {
      border-color: orange; }
    .results-wrap .results .result.result-eaa_moderate {
      border-color: yellow; }
    .results-wrap .results .result.result-eaa_conservative {
      border-color: #f6cf67; }
    .results-wrap .results .result.result-vanguard_index {
      border-color: #9174E6; }
    .results-wrap .results .result.result-sp500_index {
      border-color: #DE5574; }
    .results-wrap .results .result.result-msci_index {
      border-color: #7086B7; }
    .results-wrap .results .result.result-ftse_index {
      border-color: #ff3333; }
  .results-wrap .results .label {
    font-size: 0.625rem;
    margin-top: 0.10156rem; }
    .results-wrap .results .label.solid-line {
      margin-top: 0;
      padding-top: 0.10156rem; }
    .results-wrap .results .label.result-high {
      color: pink; }
    .results-wrap .results .label.result-low {
      color: yellow; }
    .results-wrap .results .label.result-lima_aggressive {
      color: #4DAEFB; }
    .results-wrap .results .label.result-lima_moderate {
      color: #56F1FD; }
    .results-wrap .results .label.result-Voyager {
      color: lightblue; }
    .results-wrap .results .label.result-vanguard_aggressive {
      color: #73E831; }
    .results-wrap .results .label.result-vanguard_moderate {
      color: #B1EF8F; }
    .results-wrap .results .label.result-vanguard_conservative {
      color: #4CB07D; }
    .results-wrap .results .label.result-eaa_aggressive {
      color: orange; }
    .results-wrap .results .label.result-eaa_moderate {
      color: yellow; }
    .results-wrap .results .label.result-eaa_conservative {
      color: #f6cf67; }
    .results-wrap .results .label.result-vanguard_index {
      color: #9174E6; }
    .results-wrap .results .label.result-sp500_index {
      color: #DE5574; }
    .results-wrap .results .label.result-msci_index {
      color: #7086B7; }
    .results-wrap .results .label.result-ftse_index {
      color: #ff3333; }
  @media (min-width: 1024px) {
    .results-wrap .results .result {
      font-size: 1rem; }
    .results-wrap .results .label {
      margin-top: 0.20312rem;
      font-size: 0.75rem; }
      .results-wrap .results .label.solid-line {
        padding-top: 0.20312rem; } }

.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background-color: #f5f4f2;
  text-align: center; }

.controls-tab-buttons-wrap {
  border-bottom: 1px solid #d5d2d3; }
  @media (min-width: 1024px) {
    .controls-tab-buttons-wrap {
      display: none; } }

.tab-content {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 1.625rem; }
  .tab-content .tab-content-inner {
    width: 100%; }
  .tab-content .desktop-title {
    text-align: left; }
  @media (min-width: 1024px) {
    .tab-content {
      flex-direction: column;
      justify-content: space-around;
      margin-top: 1.625rem;
      margin-bottom: 0; }
      .tab-content .tab-content-inner {
        max-width: 80%;
        margin-bottom: 1.625rem; }
      .tab-content .desktop-title {
        display: flex;
        margin-bottom: 0.8125rem;
        text-transform: uppercase;
        color: #2D2926; } }

.inv-choices-wrap {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 0.40625rem; }
  @media (min-width: 620px) {
    .inv-choices-wrap {
      padding: 0 1.625rem; } }
  @media (min-width: 1024px) {
    .inv-choices-wrap {
      padding: 0; } }

.inv-choices-button {
  flex-basis: 32%;
  position: relative;
  cursor: pointer;
  padding: 0.40625rem;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); }
  .inv-choices-button .inv-choices-button-info {
    position: relative;
    z-index: 2; }
    .inv-choices-button .inv-choices-button-info svg {
      height: 1.08333rem;
      margin-bottom: 0.40625rem; }
    .inv-choices-button .inv-choices-button-info h6 {
      text-transform: uppercase;
      font-size: 0.625rem;
      line-height: 0.8125rem; }
      @media (min-width: 375px) {
        .inv-choices-button .inv-choices-button-info h6 {
          font-size: 0.75rem; } }
      @media (min-width: 620px) {
        .inv-choices-button .inv-choices-button-info h6 {
          font-size: 0.875rem; } }
    .inv-choices-button .inv-choices-button-info p {
      font-size: 0.875rem;
      color: #BFB8AF; }
  .inv-choices-button .bg-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: 1; }
    .inv-choices-button .bg-icon svg {
      height: 100%;
      fill: #f5f4f2; }

.capital-buttons-wrap,
.digital-buttons-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8125rem; }

.capital-buttons-wrap .inv-choices-button-info svg {
  fill: #009CA6; }

.capital-buttons-wrap .inv-choices-button.selected {
  background-image: linear-gradient(90deg, #47C9D1 0%, #64a19d 100%); }

.digital-buttons-wrap .inv-choices-button-info svg {
  fill: #FFC72C; }

.digital-buttons-wrap .inv-choices-button.selected {
  background-image: linear-gradient(90deg, #f6cf67 0%, #F2A900 100%); }

.inv-choices-button.selected .inv-choices-button-info svg {
  fill: #fff; }

.inv-choices-button.selected .inv-choices-button-info p {
  color: #fff; }

.inv-choices-button.selected .bg-icon {
  opacity: 0.2; }

.investment-choice-wrap {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr; }
  .investment-choice-wrap .percentage {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 6rem;
    height: 100%;
    position: relative;
    padding: 0.40625rem; }
    .investment-choice-wrap .percentage h4 {
      font-weight: 400;
      color: #fff;
      margin-bottom: 0; }
    .investment-choice-wrap .percentage h6 {
      font-weight: 400;
      color: #f5f4f2; }
    .investment-choice-wrap .percentage svg {
      position: absolute;
      bottom: 0;
      top: 0;
      height: 100%;
      fill: #fff; }
    .investment-choice-wrap .percentage.percentage-left {
      text-align: left;
      background-color: #f6cf67; }
      .investment-choice-wrap .percentage.percentage-left svg {
        left: -0.8125rem;
        opacity: 0.2; }
    .investment-choice-wrap .percentage.percentage-right {
      text-align: right;
      background-color: #009CA6; }
      .investment-choice-wrap .percentage.percentage-right svg {
        right: -0.8125rem;
        opacity: 0.1; }
    .investment-choice-wrap .percentage.disabled {
      opacity: 0.6;
      filter: saturate(0.3); }

.slider-wrap {
  position: relative;
  width: 100%;
  padding: 2.1rem;
  height: 4rem; }
  .slider-wrap.disabled {
    opacity: 0.6;
    filter: saturate(0.3); }

.slider-handles .handle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 1rem);
  width: 2rem;
  height: 2rem;
  transform: translateX(-1rem);
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
  z-index: 3; }
  .slider-handles .handle::after {
    content: "< >";
    color: #BFB8AF;
    font-size: 0.75rem; }
  .slider-handles .handle.disabled {
    cursor: auto; }
  .slider-handles .handle.disabled::after {
    content: "-"; }

.slider-tracks {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%; }
  .slider-tracks .tracks-percentage-wrap {
    display: flex;
    height: 100%; }
    .slider-tracks .tracks-percentage-wrap .tracks-wrap {
      position: relative;
      flex-grow: 1; }
      .slider-tracks .tracks-percentage-wrap .tracks-wrap .track-left,
      .slider-tracks .tracks-percentage-wrap .tracks-wrap .track-right {
        position: absolute;
        height: 100%; }
      .slider-tracks .tracks-percentage-wrap .tracks-wrap .track-left {
        left: -1px;
        background-image: linear-gradient(90deg, #f6cf67 0%, #F2A900 100%); }
      .slider-tracks .tracks-percentage-wrap .tracks-wrap .track-right {
        right: -1px;
        background-image: linear-gradient(90deg, #47C9D1 0%, #64a19d 100%); }

.inv-choices-wrap {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 0.40625rem; }
  @media (min-width: 620px) {
    .inv-choices-wrap {
      padding: 0 1.625rem; } }
  @media (min-width: 1024px) {
    .inv-choices-wrap {
      padding: 0; } }

.indices-choices-button {
  flex-basis: 23%;
  position: relative;
  cursor: pointer;
  padding: 0.40625rem;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); }
  .indices-choices-button .inv-choices-button-info {
    position: relative;
    z-index: 2; }
    .indices-choices-button .inv-choices-button-info svg {
      height: 1.08333rem;
      margin-bottom: 0.40625rem; }
    .indices-choices-button .inv-choices-button-info h6 {
      text-transform: uppercase;
      font-size: 0.625rem;
      line-height: 0.8125rem; }
      @media (min-width: 375px) {
        .indices-choices-button .inv-choices-button-info h6 {
          font-size: 0.75rem; } }
      @media (min-width: 620px) {
        .indices-choices-button .inv-choices-button-info h6 {
          font-size: 0.875rem; } }
    .indices-choices-button .inv-choices-button-info p {
      font-size: 0.875rem;
      color: #BFB8AF; }
  .indices-choices-button .bg-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: 1; }
    .indices-choices-button .bg-icon svg {
      height: 100%;
      fill: #f5f4f2; }

.benchmark-index-buttons-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8125rem; }

.benchmark-index-buttons-wrap .vanguard-index-info svg {
  fill: #9174E6; }

.benchmark-index-buttons-wrap .vanguard-choices-button.selected {
  background-image: linear-gradient(90deg, #c4b5f2 0%, #9174E6 100%); }

.benchmark-index-buttons-wrap .msci-index-info svg {
  fill: #7086B7; }

.benchmark-index-buttons-wrap .msci-choices-button.selected {
  background-image: linear-gradient(90deg, #b4bfd9 0%, #7086B7 100%); }

.benchmark-index-buttons-wrap .sp500-index-info svg {
  fill: #DE5574; }

.benchmark-index-buttons-wrap .sp500-choices-button.selected {
  background-image: linear-gradient(90deg, #ea95a8 0%, #DE5574 100%); }

.benchmark-index-buttons-wrap .ftse-index-info svg {
  fill: #ff3333; }

.benchmark-index-buttons-wrap .ftse-choices-button.selected {
  background-image: linear-gradient(90deg, #ff9999 0%, #ff6666 100%); }

.indices-choices-button.selected .inv-choices-button-info svg {
  fill: #fff; }

.indices-choices-button.selected .inv-choices-button-info p {
  color: #fff; }

.indices-choices-button.selected .bg-icon {
  opacity: 0.2; }

.inv-choices-wrap {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 0.40625rem; }
  @media (min-width: 620px) {
    .inv-choices-wrap {
      padding: 0 1.625rem; } }
  @media (min-width: 1024px) {
    .inv-choices-wrap {
      padding: 0; } }

.inv-choices-button {
  flex-basis: 32%;
  position: relative;
  cursor: pointer;
  padding: 0.40625rem;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); }
  .inv-choices-button .inv-choices-button-info {
    position: relative;
    z-index: 2; }
    .inv-choices-button .inv-choices-button-info svg {
      height: 1.08333rem;
      margin-bottom: 0.40625rem; }
    .inv-choices-button .inv-choices-button-info h6 {
      text-transform: uppercase;
      font-size: 0.625rem;
      line-height: 0.8125rem; }
      @media (min-width: 375px) {
        .inv-choices-button .inv-choices-button-info h6 {
          font-size: 0.75rem; } }
      @media (min-width: 620px) {
        .inv-choices-button .inv-choices-button-info h6 {
          font-size: 0.875rem; } }
    .inv-choices-button .inv-choices-button-info p {
      font-size: 0.875rem;
      color: #BFB8AF; }
  .inv-choices-button .bg-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: 1; }
    .inv-choices-button .bg-icon svg {
      height: 100%;
      fill: #f5f4f2; }

.peergroups-buttons-wrap,
.digital-buttons-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8125rem; }

.peergroups-buttons-wrap .inv-choices-button-info svg {
  fill: #B1EF8F; }

.peergroups-buttons-wrap .inv-choices-button.selected {
  background-image: linear-gradient(90deg, #B1EF8F 0%, #4CB07D 100%); }

.digital-buttons-wrap .inv-choices-button-info svg {
  fill: #FFC72C; }

.digital-buttons-wrap .inv-choices-button.selected {
  background-image: linear-gradient(90deg, #f6cf67 0%, #F2A900 100%); }

.inv-choices-button.selected .inv-choices-button-info svg {
  fill: #fff; }

.inv-choices-button.selected .inv-choices-button-info p {
  color: #fff; }

.inv-choices-button.selected .bg-icon {
  opacity: 0.2; }

.inv-amount-wrap {
  width: 100%;
  max-width: 40rem;
  overflow: hidden;
  padding: 0 0.8125rem;
  margin: 0 auto; }
  .inv-amount-wrap .amount-input {
    position: relative; }
    .inv-amount-wrap .amount-input::before {
      content: 'US$';
      position: absolute;
      bottom: 0.40625rem;
      font-size: 1.125rem; }
    .inv-amount-wrap .amount-input input {
      margin-left: 2.4375rem;
      width: calc(100% - (1.625rem * 1.5)); }
  @media (min-width: 1024px) {
    .inv-amount-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: none;
      padding: 0; }
      .inv-amount-wrap .amount-input {
        flex-basis: 48%;
        margin-bottom: 0; } }

.timeframe-wrap {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 0.40625rem; }
  @media (min-width: 620px) {
    .timeframe-wrap {
      padding: 0 1.625rem; } }
  @media (min-width: 1024px) {
    .timeframe-wrap {
      padding: 0; } }

.timeframe-button {
  flex-basis: 15%;
  position: relative;
  cursor: pointer;
  padding: 0.40625rem;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); }
  .timeframe-button .timeframe-button-info {
    position: relative;
    z-index: 2; }
    .timeframe-button .timeframe-button-info svg {
      height: 1.08333rem;
      margin-bottom: 0.40625rem; }
    .timeframe-button .timeframe-button-info h6 {
      text-transform: uppercase;
      font-size: 0.625rem;
      line-height: 0.8125rem; }
      @media (min-width: 375px) {
        .timeframe-button .timeframe-button-info h6 {
          font-size: 0.75rem; } }
      @media (min-width: 620px) {
        .timeframe-button .timeframe-button-info h6 {
          font-size: 0.875rem; } }
    .timeframe-button .timeframe-button-info p {
      font-size: 0.875rem;
      color: #BFB8AF; }

.timeframe-buttons-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8125rem; }

.timeframe-buttons-wrap .timeframe-button.selected {
  background-image: linear-gradient(90deg, #796E65 0%, #151211 50%);
  color: white; }

#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif; }

.background-video {
  position: fixed;
  right: 0;
  min-width: 100%;
  min-height: 100%; }

.content {
  position: fixed;
  width: 100%; }

@media (max-width: 620px) {
  .content {
    position: relative; }
  .walkthrough-intro-header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 100%;
    background: linear-gradient(90deg, #47C9D1 0%, #009CA6 50%, #4EC0C8 100%);
    background-size: 500% 500%;
    -webkit-animation: gradientMotion 5s ease infinite;
    -moz-animation: gradientMotion 5s ease infinite;
    animation: gradientMotion 5s ease infinite; }
    .walkthrough-intro-header img {
      width: 4.875rem;
      margin: 3.25rem auto 13rem; } }

.walkthrough-intro-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.walkthrough-intro-header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 100%; }
  .walkthrough-intro-header img {
    width: 4.875rem;
    margin: 3.25rem auto 13rem; }

@-webkit-keyframes gradientMotion {
  0% {
    background-position: 0% 25%; }
  50% {
    background-position: 60% 40%; }
  100% {
    background-position: 0% 50%; } }

@-moz-keyframes gradientMotion {
  0% {
    background-position: 0% 25%; }
  50% {
    background-position: 60% 40%; }
  100% {
    background-position: 0% 50%; } }

@keyframes gradientMotion {
  0% {
    background-position: 0% 25%; }
  50% {
    background-position: 60% 40%; }
  100% {
    background-position: 0% 50%; } }

.walkthrough-intro-content {
  max-width: 46rem;
  margin: -9.75rem 0 1.625rem;
  padding: 1.625rem;
  background-color: #fff; }
  @media (min-width: 620px) {
    .walkthrough-intro-content {
      padding: 4.0625rem;
      margin-left: 1.625rem;
      margin-right: 1.625rem; } }
  .walkthrough-intro-content .intro-buttons-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.625rem; }
  .walkthrough-intro-content .link-button {
    padding: 0;
    color: #009CA6; }
  .walkthrough-intro-content p {
    margin-bottom: 1.625rem; }
  .walkthrough-intro-content small {
    font-size: 0.75rem; }

.walkthrough-steps {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .walkthrough-steps.graph {
    flex-direction: column-reverse; }

.why-component {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  flex-basis: 100%; }
  @media (min-width: 1024px) {
    .why-component {
      flex-direction: column;
      justify-content: space-between;
      width: 50%; } }
  .why-component .why-header {
    display: none; }
    @media (min-width: 1024px) {
      .why-component .why-header {
        display: block;
        padding: 1.625rem;
        text-align: center; }
        .why-component .why-header.blue {
          background-image: linear-gradient(90deg, #47C9D1 0%, #64a19d 100%); }
        .why-component .why-header.green {
          background-image: linear-gradient(90deg, #B1EF8F 0%, #4CB07D 100%); }
        .why-component .why-header.grey {
          background-image: linear-gradient(90deg, #2D2926 0%, #151211 50%); }
        .why-component .why-header.yellow {
          background-image: linear-gradient(90deg, #f6cf67 0%, #F2A900 100%); }
        .why-component .why-header img {
          height: 100%;
          max-height: 12rem; } }
    @media (min-width: 1365px) {
      .why-component .why-header {
        padding: 3.25rem; } }
  .why-component .why-content {
    padding: 1.625rem; }
    @media (min-width: 1024px) {
      .why-component .why-content {
        max-width: 30rem;
        margin-left: auto;
        margin-right: auto; } }
    .why-component .why-content ul li {
      list-style: disc;
      margin-left: 1rem; }
    .why-component .why-content h3 {
      font-size: 1rem; }
    .why-component .why-content p {
      margin-bottom: 1.5rem; }
    @media (min-width: 620px) {
      .why-component .why-content .read-more {
        display: none; } }
    .why-component .why-content .read-more.expanded {
      display: none; }
    .why-component .why-content .full-content {
      display: none; }
      @media (min-width: 620px) {
        .why-component .why-content .full-content {
          display: block; } }
      .why-component .why-content .full-content.expanded {
        display: block; }
  .why-component .link-button {
    padding: 0;
    color: #009CA6; }
  .why-component .savings-goals .goal {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .why-component .savings-goals .goal img {
      width: 5rem;
      margin: 1rem; }
  .why-component .savings-goals .university {
    flex-direction: row-reverse; }

.walkthrough-how {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 1.625rem;
  background: #f5f4f2; }
  @media (min-width: 1024px) {
    .walkthrough-how {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%; } }
  .walkthrough-how .how-inner {
    width: 100%;
    max-width: 40rem;
    margin-bottom: 1.625rem; }
    .walkthrough-how .how-inner .monthly-contribution-note {
      width: calc(50% + 1rem); }
      .walkthrough-how .how-inner .monthly-contribution-note .onboard-note {
        margin-left: auto; }

.walkthrough-navigation-footer {
  display: flex;
  z-index: 10;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  height: 4.0625rem;
  padding: 0.8125rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2); }
  @media (min-width: 1024px) {
    .walkthrough-navigation-footer {
      padding: 0.8125rem 1.625rem; } }
  .walkthrough-navigation-footer .walkthrough-navigation-buttons {
    display: flex; }
    .walkthrough-navigation-footer .walkthrough-navigation-buttons .icon-button {
      margin-right: 0.8125rem; }
      .walkthrough-navigation-footer .walkthrough-navigation-buttons .icon-button:disabled {
        background: #d5d2d3;
        cursor: not-allowed; }

.walkthrough-how {
  padding-bottom: 3rem; }

@media (min-width: 1024px) {
  .why-component,
  .walkthrough-how {
    padding-bottom: 3rem; } }

.walkthrough-graph-step .why-component {
  width: 100%; }
  .walkthrough-graph-step .why-component .why-content {
    padding-bottom: 6rem; }

.walkthrough-graph-step .graph {
  margin-top: 1rem; }

.application-form-wrap {
  background-image: linear-gradient(90deg, #2D2926 0%, #151211 50%);
  color: #fff;
  padding: 1.625rem;
  min-height: 100vh;
  position: relative;
  padding-top: 200px; }
  .application-form-wrap .header-section .logo-wrapper {
    position: absolute;
    top: -2px;
    right: 0;
    height: 200px; }
    .application-form-wrap .header-section .logo-wrapper img.sploosh {
      height: 100%; }
    .application-form-wrap .header-section .logo-wrapper img.logo {
      width: 60px;
      position: absolute;
      top: 3.25rem;
      right: 3.25rem; }
  .application-form-wrap .header-section h1 {
    font-size: 2.9375rem;
    margin-bottom: 1.625rem; }
  .application-form-wrap .header-section p {
    margin-bottom: 3.25rem; }
  .application-form-wrap hr {
    margin: 4.875rem 0; }
  .application-form-wrap .field {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.625rem; }
    .application-form-wrap .field label,
    .application-form-wrap .field .input {
      flex: 1;
      width: 50%; }
    .application-form-wrap .field > label {
      color: #fff; }
      .application-form-wrap .field > label.valid {
        color: #FFC72C; }
    .application-form-wrap .field .input {
      margin: 0;
      border: none; }
    .application-form-wrap .field input:not([type]),
    .application-form-wrap .field input[type="date"],
    .application-form-wrap .field input[type="datetime-local"],
    .application-form-wrap .field input[type="email"],
    .application-form-wrap .field input[type="file"],
    .application-form-wrap .field input[type="number"],
    .application-form-wrap .field input[type="password"],
    .application-form-wrap .field input[type="search"],
    .application-form-wrap .field input[type="tel"],
    .application-form-wrap .field input[type="text"],
    .application-form-wrap .field input[type="time"],
    .application-form-wrap .field input[type="url"],
    .application-form-wrap .field .selection.dropdown {
      color: #fff;
      caret-color: auto;
      border-radius: 0.75rem;
      border: 1px solid #fff;
      background: transparent;
      margin: 0; }
      .application-form-wrap .field input:not([type]):focus, .application-form-wrap .field input:not([type]).active,
      .application-form-wrap .field input[type="date"]:focus,
      .application-form-wrap .field input[type="date"].active,
      .application-form-wrap .field input[type="datetime-local"]:focus,
      .application-form-wrap .field input[type="datetime-local"].active,
      .application-form-wrap .field input[type="email"]:focus,
      .application-form-wrap .field input[type="email"].active,
      .application-form-wrap .field input[type="file"]:focus,
      .application-form-wrap .field input[type="file"].active,
      .application-form-wrap .field input[type="number"]:focus,
      .application-form-wrap .field input[type="number"].active,
      .application-form-wrap .field input[type="password"]:focus,
      .application-form-wrap .field input[type="password"].active,
      .application-form-wrap .field input[type="search"]:focus,
      .application-form-wrap .field input[type="search"].active,
      .application-form-wrap .field input[type="tel"]:focus,
      .application-form-wrap .field input[type="tel"].active,
      .application-form-wrap .field input[type="text"]:focus,
      .application-form-wrap .field input[type="text"].active,
      .application-form-wrap .field input[type="time"]:focus,
      .application-form-wrap .field input[type="time"].active,
      .application-form-wrap .field input[type="url"]:focus,
      .application-form-wrap .field input[type="url"].active,
      .application-form-wrap .field .selection.dropdown:focus,
      .application-form-wrap .field .selection.dropdown.active {
        border-color: #FFC72C;
        color: #fff; }
    .application-form-wrap .field + .input-error.error {
      width: 50%;
      margin-left: 50%;
      color: #FC5A61; }
    .application-form-wrap .field .selection.dropdown .text {
      background: none;
      color: inherit; }
    .application-form-wrap .field .selection.dropdown .menu {
      color: #fff;
      caret-color: auto;
      border-radius: 0 0 0.75rem 0.75rem;
      border: 1px solid #FFC72C;
      background: #373634; }
      .application-form-wrap .field .selection.dropdown .menu .item {
        border: none;
        color: inherit; }
    .application-form-wrap .field.toggle-button-wrap .toggle-button {
      flex: 1;
      padding: 0 0.8125rem 0 0;
      margin: 0; }
      .application-form-wrap .field.toggle-button-wrap .toggle-button .button {
        background: #373634;
        width: 100%;
        color: #fff; }
      .application-form-wrap .field.toggle-button-wrap .toggle-button.active .button {
        background-image: linear-gradient(90deg, #f6cf67 0%, #F2A900 100%);
        color: #000; }
      .application-form-wrap .field.toggle-button-wrap .toggle-button:last-child {
        padding: 0; }
    .application-form-wrap .field.date-selector > .input {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 0; }
      .application-form-wrap .field.date-selector > .input .field {
        flex: 1;
        padding: 0 0.8125rem 0 0;
        margin: 0; }
        .application-form-wrap .field.date-selector > .input .field .selection.dropdown {
          width: 100%; }
        .application-form-wrap .field.date-selector > .input .field:last-child {
          padding: 0; }
    .application-form-wrap .field.error {
      color: #FC5A61; }
      .application-form-wrap .field.error > label,
      .application-form-wrap .field.error ::placeholder {
        color: #FC5A61; }
      .application-form-wrap .field.error input:not([type]),
      .application-form-wrap .field.error input[type="date"],
      .application-form-wrap .field.error input[type="datetime-local"],
      .application-form-wrap .field.error input[type="email"],
      .application-form-wrap .field.error input[type="file"],
      .application-form-wrap .field.error input[type="number"],
      .application-form-wrap .field.error input[type="password"],
      .application-form-wrap .field.error input[type="search"],
      .application-form-wrap .field.error input[type="tel"],
      .application-form-wrap .field.error input[type="text"],
      .application-form-wrap .field.error input[type="time"],
      .application-form-wrap .field.error input[type="url"],
      .application-form-wrap .field.error .selection.dropdown {
        border-color: #FC5A61;
        color: #FC5A61; }
        .application-form-wrap .field.error input:not([type]):focus, .application-form-wrap .field.error input:not([type]).active,
        .application-form-wrap .field.error input[type="date"]:focus,
        .application-form-wrap .field.error input[type="date"].active,
        .application-form-wrap .field.error input[type="datetime-local"]:focus,
        .application-form-wrap .field.error input[type="datetime-local"].active,
        .application-form-wrap .field.error input[type="email"]:focus,
        .application-form-wrap .field.error input[type="email"].active,
        .application-form-wrap .field.error input[type="file"]:focus,
        .application-form-wrap .field.error input[type="file"].active,
        .application-form-wrap .field.error input[type="number"]:focus,
        .application-form-wrap .field.error input[type="number"].active,
        .application-form-wrap .field.error input[type="password"]:focus,
        .application-form-wrap .field.error input[type="password"].active,
        .application-form-wrap .field.error input[type="search"]:focus,
        .application-form-wrap .field.error input[type="search"].active,
        .application-form-wrap .field.error input[type="tel"]:focus,
        .application-form-wrap .field.error input[type="tel"].active,
        .application-form-wrap .field.error input[type="text"]:focus,
        .application-form-wrap .field.error input[type="text"].active,
        .application-form-wrap .field.error input[type="time"]:focus,
        .application-form-wrap .field.error input[type="time"].active,
        .application-form-wrap .field.error input[type="url"]:focus,
        .application-form-wrap .field.error input[type="url"].active,
        .application-form-wrap .field.error .selection.dropdown:focus,
        .application-form-wrap .field.error .selection.dropdown.active {
          background: transparent; }
      .application-form-wrap .field.error + .input-error.error {
        margin-top: -1.625rem;
        margin-bottom: 1.625rem; }
      .application-form-wrap .field.error:not(.toggle-button-wrap):not(.selector) .input {
        position: relative; }
        .application-form-wrap .field.error:not(.toggle-button-wrap):not(.selector) .input:after {
          content: "x";
          position: absolute;
          top: 50%;
          right: 0.54167rem;
          color: #FC5A61;
          transform: translateY(-50%); }
      .application-form-wrap .field.error .selection.dropdown {
        border-color: #FC5A61 !important; }
        .application-form-wrap .field.error .selection.dropdown .menu {
          color: #fff;
          border-color: #FC5A61 !important;
          background: #373634; }
          .application-form-wrap .field.error .selection.dropdown .menu .item {
            border: none;
            color: inherit;
            background: transparent; }
    .application-form-wrap .field.valid > label,
    .application-form-wrap .field.valid ::placeholder {
      color: #FFC72C; }
    .application-form-wrap .field.valid input:not([type]),
    .application-form-wrap .field.valid input[type="date"],
    .application-form-wrap .field.valid input[type="datetime-local"],
    .application-form-wrap .field.valid input[type="email"],
    .application-form-wrap .field.valid input[type="file"],
    .application-form-wrap .field.valid input[type="number"],
    .application-form-wrap .field.valid input[type="password"],
    .application-form-wrap .field.valid input[type="search"],
    .application-form-wrap .field.valid input[type="tel"],
    .application-form-wrap .field.valid input[type="text"],
    .application-form-wrap .field.valid input[type="time"],
    .application-form-wrap .field.valid input[type="url"],
    .application-form-wrap .field.valid .selection.dropdown {
      border-color: #FFC72C;
      color: #fff; }
      .application-form-wrap .field.valid input:not([type]):focus, .application-form-wrap .field.valid input:not([type]).active,
      .application-form-wrap .field.valid input[type="date"]:focus,
      .application-form-wrap .field.valid input[type="date"].active,
      .application-form-wrap .field.valid input[type="datetime-local"]:focus,
      .application-form-wrap .field.valid input[type="datetime-local"].active,
      .application-form-wrap .field.valid input[type="email"]:focus,
      .application-form-wrap .field.valid input[type="email"].active,
      .application-form-wrap .field.valid input[type="file"]:focus,
      .application-form-wrap .field.valid input[type="file"].active,
      .application-form-wrap .field.valid input[type="number"]:focus,
      .application-form-wrap .field.valid input[type="number"].active,
      .application-form-wrap .field.valid input[type="password"]:focus,
      .application-form-wrap .field.valid input[type="password"].active,
      .application-form-wrap .field.valid input[type="search"]:focus,
      .application-form-wrap .field.valid input[type="search"].active,
      .application-form-wrap .field.valid input[type="tel"]:focus,
      .application-form-wrap .field.valid input[type="tel"].active,
      .application-form-wrap .field.valid input[type="text"]:focus,
      .application-form-wrap .field.valid input[type="text"].active,
      .application-form-wrap .field.valid input[type="time"]:focus,
      .application-form-wrap .field.valid input[type="time"].active,
      .application-form-wrap .field.valid input[type="url"]:focus,
      .application-form-wrap .field.valid input[type="url"].active,
      .application-form-wrap .field.valid .selection.dropdown:focus,
      .application-form-wrap .field.valid .selection.dropdown.active {
        background: transparent; }
    .application-form-wrap .field.valid .selection.dropdown {
      border-color: #FFC72C !important; }
    .application-form-wrap .field.valid:not(.toggle-button-wrap):not(.selector) .input {
      position: relative; }
      .application-form-wrap .field.valid:not(.toggle-button-wrap):not(.selector) .input:after {
        content: "✓";
        position: absolute;
        top: 50%;
        right: 0.54167rem;
        color: #FFC72C;
        transform: translateY(-50%); }
  .application-form-wrap .ol.numbered-list {
    list-style-type: upper-roman;
    padding: 20px; }

.application-form-submit {
  background-image: linear-gradient(90deg, #2D2926 0%, #151211 50%);
  color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .application-form-submit > * {
    display: block; }
  .application-form-submit h2 {
    color: #FFC72C;
    font-size: 2.9375rem; }
  .application-form-submit h4 {
    font-size: 2.0625rem;
    margin-bottom: 0.8125rem; }

.submission-error {
  color: #FC5A61;
  text-align: center; }
